var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"relative"},[_c('div',{staticClass:"flex justify-between"},[(_vm.label)?_c('div',{staticClass:"dropdown-label text-sm font-semibold text-gray-800 mb-2"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.invalid)?_c('span',{staticClass:"text-small text-red-600 font-light"},[_vm._v(" Required field ")]):_vm._e()]),_c('button',{ref:"trigger",staticClass:"trigger group flex justify-between items-center text-base text-gray-600 font-normal rounded cursor-pointer origin-bottom w-full focus:outline-none transition duration-200 ease",class:[
      _vm.customClass,
      {
        'p-4': _vm.scheme === _vm.SCHEMES.transparent,
        'bg-white border': _vm.scheme === _vm.SCHEMES.primary,
        'py-5': !!_vm.label,
        'border-gray-300 hover:border-gray-800': !_vm.ariaExpanded && _vm.scheme === _vm.SCHEMES.primary,
        'outline-none border-primary': _vm.ariaExpanded && _vm.scheme === _vm.SCHEMES.primary,
        'border-red': _vm.invalid,
        disabled: _vm.disabled,
        'text-gray-900': _vm.selectedOption,
        'p-4 shadow-md': !_vm.darkStyles && _vm.scheme === _vm.SCHEMES.primary,
        'py-3 px-4 bg-gray-300': _vm.darkStyles && _vm.scheme === _vm.SCHEMES.primary,
      },
    ],attrs:{"aria-haspopup":"listbox","aria-expanded":_vm.ariaExpanded,"disabled":_vm.disabled},on:{"click":_vm.toggleMenu,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.close.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.open.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.close.apply(null, arguments)}]}},[(_vm.selectedOption && _vm.selectedOption.iconColorSmall)?_c('span',{staticClass:"w-2 h-2 rounded-lg inline-block mr-2",class:_vm.selectedOption.iconColorSmall}):_vm._e(),(_vm.selectedOption && _vm.selectedOption.iconColorLarge)?_c('span',{staticClass:"iconColorLarge rounded-full flex justify-center items-center mr-2",class:_vm.selectedOption.iconColorLarge,style:({ 'background-color': `rgba(${_vm.selectedOption.value}, 0.2)` })},[_c('span',{staticClass:"w-3 h-3 rounded-full",class:_vm.selectedOption.iconColorLarge})]):_vm._e(),_c('span',{staticClass:"placeholder mr-auto flex",class:[
        _vm.placeholderClass,
        {
          'font-bold font-sans gray-900 text-base': _vm.darkStyles,
          'group-hover:text-primary': _vm.scheme === _vm.SCHEMES.transparent,
        },
      ]},[(_vm.selectedOption && _vm.selectedOption.icon)?[(_vm.selectedOption.icon === 'academic')?_c('AcademicIcon',{staticClass:"flex-shrink-0 w-5 h-6 mr-2"}):(_vm.selectedOption.icon === 'work')?_c('WorkIcon',{staticClass:"flex-shrink-0 w-5 h-6 mr-2"}):(_vm.selectedOption.icon === 'home')?_c('FamilyIcon',{staticClass:"flex-shrink-0 w-5 h-6 mr-2"}):(_vm.selectedOption.icon === 'users')?_c('ClubIcon',{staticClass:"flex-shrink-0 w-5 h-6 mr-2"}):_vm._e()]:_vm._e(),_vm._v(" "+_vm._s((_vm.selectedOption && _vm.selectedOption.label) || _vm.placeholder)+" ")],2),_c('span',{staticClass:"inline-block transition-transform duration-200 ease transform",class:{
        'rotate-180': _vm.ariaExpanded,
      },attrs:{"aria-hidden":"true"}},[_c('Chevron',{staticClass:"w-3 h-3",class:{ 'group-hover:fill-primary': _vm.scheme === _vm.SCHEMES.transparent }})],1)]),_c('div',{staticClass:"menu-container absolute bg-white left-0 overflow-hidden rounded shadow-md z-10 inline-block text-gray-600 origin-top transition-transform duration-200 ease min-w-full mt-1",class:[{ 'menu-hidden pointer-events-none': !_vm.ariaExpanded }, { 'pr-3': _vm.scrollOverflow }]},[_c('div',{staticClass:"menu custom-scroll",class:[
        { 'menu-hidden': !_vm.ariaExpanded },
        { 'overflow-y-scroll': _vm.scrollOverflow },
        { 'overflow-hidden': !_vm.scrollOverflow },
        { 'big-menu': _vm.bigDropdown },
        { 'bg-gray-200': _vm.darkStyles },
      ]},[_c('ul',{ref:"menu",staticClass:"py-2 pl-2 border border-gray-300",class:{ 'pr-3': _vm.scrollOverflow, 'pr-2': !_vm.scrollOverflow },attrs:{"role":"listbox"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.moveDown()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.moveUp()},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.close.apply(null, arguments)}]}},[(_vm.$slots.default)?[_vm._t("default")]:_vm._l((_vm.options),function(option,index){return _c('li',{key:`${option.value}_${index}`,staticClass:"cursor-pointer p-3 text-sm font-normal text-left w-full whitespace-no-wrap hover:text-primary flex rounded items-center animation-focus-default focus:outline-none transition-colors duration-100 ease",class:[
            {
              'hover:bg-primary-transparent focus:bg-primary-transparent': _vm.primaryColor,
              'bg-white': option === _vm.selectedOption && _vm.darkStyles,
              'mb-1': index !== _vm.options.length - 1,
              'hover:bg-gray-100': !_vm.darkStyles && !_vm.primaryColor,
              'hover:bg-white': _vm.darkStyles,
            },
          ],attrs:{"role":"option","tabindex":"-1"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();$event.preventDefault();return _vm.selectOption(option, index)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.selectOption(option, index)}],"click":function($event){return _vm.selectOption(option, index)}}},[(option.iconColorLarge && option.value)?_c('span',{staticClass:"flex w-4 h-4 justify-center items-center rounded-full ml-1 mr-2",style:({ 'background-color': `rgba(${option.value}, 0.2)` })},[_c('span',{staticClass:"w-2 h-2 rounded-full",class:option.iconColorLarge})]):_vm._e(),(option.icon)?[(option.icon === 'academic')?_c('AcademicIcon',{staticClass:"flex-shrink-0 w-4 mr-2"}):(option.icon === 'work')?_c('WorkIcon',{staticClass:"flex-shrink-0 w-4 mr-2"}):(option.icon === 'home')?_c('FamilyIcon',{staticClass:"flex-shrink-0 w-4 mr-2"}):(option.icon === 'users')?_c('ClubIcon',{staticClass:"flex-shrink-0 w-4 mr-2"}):_vm._e()]:_vm._e(),_vm._v(" "+_vm._s(option.label)+" "),(option === _vm.selectedOption)?_c('span',[_c('Check',{staticClass:"ml-1 scale-75 check"})],1):_vm._e()],2)})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }