var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full"},[_c('textarea',{ref:"input",staticClass:"input w-full text-gray-900 bg-white rounded shadow-drop text-sm lg:text-lg placeholder-gray-600 focus:outline-none px-4 min-h-[130px] lg:min-h-32 border transition-colors duration-200 ease resize-none",class:[
      { 'input--filled': _vm.value && _vm.value.length > 0 },
      { 'border-red-600': _vm.invalid },
      { 'border-gray-300': !_vm.invalid },
      { 'pt-7 pb-3': _vm.label && !_vm.compact },
      { 'py-6': !_vm.label && !_vm.compact },
      { 'py-2 px-2': _vm.compact },
    ],attrs:{"autocomplete":"disabled","autocorrect":"false","autocapitalize":_vm.autocapitalize ? 'sentences' : 'none',"spellcheck":"false","id":_vm.id,"name":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder,"rows":_vm.rowCount,"maxlength":_vm.maxLength},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue()},"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false}}}),(_vm.label)?[_c('label',{staticClass:"input__label absolute left-0 text-gray-600 mx-4 pointer-events-none mt-2 origin-top-left transition duration-200 ease-out",class:[{ 'text-red-600': _vm.invalid }],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }