<template>
  <div class="radio">
      <input
        @change="updateValue"
        :id="id"
        type="radio"
        ref="radio"
        class="radio-input"
        :name="name"
        :checked="isChecked"
        :disabled="disabled"/>
      <label
        class="radio-label"
        :for="id">
        {{ label }}
      </label>
  </div>
</template>
<script>
export default {
  name: 'RadioButton',
  computed: {
    isChecked() {
      return this.checked === this.value;
    },
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * The value for the checkbox input
     */
    value: {
      type: String,
    },
    /**
     * This is used from v-model in place of using value
     */
    checked: {},
    /**
     * The text that will be displayed next to the checkbox
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Determines whether the checkbox is disabled or not
     */
    disabled: {
      type: Boolean,
    },
    /**
     * The group name for the radio button
     */
    name: {
      type: String,
    },
  },
  methods: {
    updateValue() {
      this.$emit('change', this.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.radio {
  @apply inline-block relative pl-6 h-6;
  min-height: 1.5rem;
  margin-bottom: 10px;
}

.radio-input {
  @apply cursor-pointer absolute z-10 m-0 opacity-0 w-6 h-6 top-0 left-0;
}

.radio-label {
  @apply inline-block mb-0 cursor-pointer pl-4 text-gray-600;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transition: color 0.2s ease;

  &::before {
     @apply absolute w-6 h-6 border-2 border-solid border-white top-0 left-0 rounded-full bg-white
     shadow-md;
     content: "";
     transition: border-color 0.2s ease, background-color 0.2s ease;
  }

  &::after {
     @apply absolute opacity-0 bg-white rounded-full top-0;
     content: '';
     width: 10px;
     height: 10px;
     left: 0.45rem;
     top: 0.45rem;
  }
}

.radio-input:checked + .radio-label {
  @apply text-gray-900;
}

.radio-input:checked + .radio-label::after {
  @apply opacity-100;
}

.radio-input:checked + .radio-label::before {
  @apply border-primary bg-primary;
}

.radio-input:focus + .radio-label::before, .radio-input:hover + .radio-label::before {
  @apply border-primary outline-none;
}

.radio-input:checked:focus + .radio-label::before,
.radio-input:checked:hover + .radio-label::before {
  @apply border-white outline-none;
}

/* Disabled styles */
.radio-input:disabled {
  @apply cursor-default;
}

.radio-input:disabled + .radio-label {
  @apply cursor-default;

  &::before {
     @apply bg-gray-400 border-gray-400 shadow-none cursor-default;
  }

  &::after {
    @apply bg-gray-400;
   }
}

.radio-input:disabled:checked + .radio-label {
  @apply text-gray-400;

  &::before {
     @apply bg-gray-600 border-gray-600 shadow-none cursor-default;
   }

  &::after {
     @apply bg-gray-400;
   }
}

.radio-input:disabled:checked:hover + .radio-label::before {
  @apply border-gray-600 cursor-default outline-none;
}

</style>
