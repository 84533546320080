<template>
  <div class="relative" v-click-outside="close">
    <div class="flex justify-between">
      <div v-if="label" class="dropdown-label text-sm font-semibold text-gray-800 mb-2">
        {{ label }}
      </div>
      <span v-if="invalid" class="text-small text-red-600 font-light"> Required field </span>
    </div>

    <button
      @click="toggleMenu"
      @keydown.up.stop.prevent="close"
      @keydown.down.stop.prevent="open"
      @keydown.tab="close"
      class="trigger group flex justify-between items-center text-base text-gray-600 font-normal rounded cursor-pointer origin-bottom w-full focus:outline-none transition duration-200 ease"
      :class="[
        customClass,
        {
          'p-4': scheme === SCHEMES.transparent,
          'bg-white border': scheme === SCHEMES.primary,
          'py-5': !!label,
          'border-gray-300 hover:border-gray-800': !ariaExpanded && scheme === SCHEMES.primary,
          'outline-none border-primary': ariaExpanded && scheme === SCHEMES.primary,
          'border-red': invalid,
          disabled: disabled,
          'text-gray-900': selectedOption,
          'p-4 shadow-md': !darkStyles && scheme === SCHEMES.primary,
          'py-3 px-4 bg-gray-300': darkStyles && scheme === SCHEMES.primary,
        },
      ]"
      aria-haspopup="listbox"
      :aria-expanded="ariaExpanded"
      :disabled="disabled"
      ref="trigger"
    >
      <span
        v-if="selectedOption && selectedOption.iconColorSmall"
        class="w-2 h-2 rounded-lg inline-block mr-2"
        :class="selectedOption.iconColorSmall"
      ></span>

      <span
        v-if="selectedOption && selectedOption.iconColorLarge"
        class="iconColorLarge rounded-full flex justify-center items-center mr-2"
        :class="selectedOption.iconColorLarge"
        :style="{ 'background-color': `rgba(${selectedOption.value}, 0.2)` }"
      >
        <span class="w-3 h-3 rounded-full" :class="selectedOption.iconColorLarge"></span>
      </span>

      <span
        class="placeholder mr-auto flex"
        :class="[
          placeholderClass,
          {
            'font-bold font-sans gray-900 text-base': darkStyles,
            'group-hover:text-primary': scheme === SCHEMES.transparent,
          },
        ]"
      >
        <template v-if="selectedOption && selectedOption.icon">
          <AcademicIcon
            v-if="selectedOption.icon === 'academic'"
            class="flex-shrink-0 w-5 h-6 mr-2"
          />
          <WorkIcon v-else-if="selectedOption.icon === 'work'" class="flex-shrink-0 w-5 h-6 mr-2" />
          <FamilyIcon
            v-else-if="selectedOption.icon === 'home'"
            class="flex-shrink-0 w-5 h-6 mr-2"
          />
          <ClubIcon
            v-else-if="selectedOption.icon === 'users'"
            class="flex-shrink-0 w-5 h-6 mr-2"
          />
        </template>
        {{ (selectedOption && selectedOption.label) || placeholder }}
      </span>
      <span
        class="inline-block transition-transform duration-200 ease transform"
        :class="{
          'rotate-180': ariaExpanded,
        }"
        aria-hidden="true"
      >
        <Chevron
          class="w-3 h-3"
          :class="{ 'group-hover:fill-primary': scheme === SCHEMES.transparent }"
        />
      </span>
    </button>

    <div
      class="menu-container absolute bg-white left-0 overflow-hidden rounded shadow-md z-10 inline-block text-gray-600 origin-top transition-transform duration-200 ease min-w-full mt-1"
      :class="[{ 'menu-hidden pointer-events-none': !ariaExpanded }, { 'pr-3': scrollOverflow }]"
    >
      <div
        class="menu custom-scroll"
        :class="[
          { 'menu-hidden': !ariaExpanded },
          { 'overflow-y-scroll': scrollOverflow },
          { 'overflow-hidden': !scrollOverflow },
          { 'big-menu': bigDropdown },
          { 'bg-gray-200': darkStyles },
        ]"
      >
        <ul
          class="py-2 pl-2 border border-gray-300"
          :class="{ 'pr-3': scrollOverflow, 'pr-2': !scrollOverflow }"
          role="listbox"
          ref="menu"
          @keydown.down.stop.prevent="moveDown()"
          @keydown.up.stop.prevent="moveUp()"
          @keydown.tab="close"
        >
          <template v-if="$slots.default">
            <slot></slot>
          </template>

          <li
            v-else
            v-for="(option, index) in options"
            @keydown.enter.stop.prevent="selectOption(option, index)"
            @keydown.space.stop.prevent="selectOption(option, index)"
            @click="selectOption(option, index)"
            class="cursor-pointer p-3 text-sm font-normal text-left w-full whitespace-no-wrap hover:text-primary flex rounded items-center animation-focus-default focus:outline-none transition-colors duration-100 ease"
            :class="[
              {
                'hover:bg-primary-transparent focus:bg-primary-transparent': primaryColor,
                'bg-white': option === selectedOption && darkStyles,
                'mb-1': index !== options.length - 1,
                'hover:bg-gray-100': !darkStyles && !primaryColor,
                'hover:bg-white': darkStyles,
              },
            ]"
            role="option"
            tabindex="-1"
            :key="`${option.value}_${index}`"
          >
            <span
              class="flex w-4 h-4 justify-center items-center rounded-full ml-1 mr-2"
              :style="{ 'background-color': `rgba(${option.value}, 0.2)` }"
              v-if="option.iconColorLarge && option.value"
            >
              <span class="w-2 h-2 rounded-full" :class="option.iconColorLarge"></span>
            </span>

            <template v-if="option.icon">
              <AcademicIcon v-if="option.icon === 'academic'" class="flex-shrink-0 w-4 mr-2" />
              <WorkIcon v-else-if="option.icon === 'work'" class="flex-shrink-0 w-4 mr-2" />
              <FamilyIcon v-else-if="option.icon === 'home'" class="flex-shrink-0 w-4 mr-2" />
              <ClubIcon v-else-if="option.icon === 'users'" class="flex-shrink-0 w-4 mr-2" />
            </template>

            {{ option.label }}
            <span v-if="option === selectedOption"><Check class="ml-1 scale-75 check" /></span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Chevron from '@/assets/chevron.svg';
import AcademicIcon from '@/assets/icon_academic.svg';
import WorkIcon from '@/assets/icon_work.svg';
import FamilyIcon from '@/assets/icon_home.svg';
import ClubIcon from '@/assets/icon_users.svg';
import Check from '@/assets/check.svg';

const SCHEMES = {
  primary: 'primary',
  transparent: 'transparent',
};

export default {
  name: 'Dropdown',
  components: {
    Check,
    Chevron,
    AcademicIcon,
    WorkIcon,
    FamilyIcon,
    ClubIcon,
  },
  props: {
    scheme: {
      type: String,
      default: SCHEMES.primary,
    },
    /**
     * Determines the label that will be displayed for the Dropdown
     */
    selectedOption: {
      type: Object,
    },
    /**
     * The options passed into the Dropdown component.
     */
    options: {
      type: Array,
      required: true,
    },
    /**
     * Determines whether to display the Dropdown trigger to the full width of its parent
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines whether to display a visual indication that Dropdown value is invalid
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines whether Dropdown can be interacted with
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Placeholder text that is shown on the Trigger if there is no default selection
     */
    placeholder: {
      type: String,
    },
    /**
     * Label for dropdown
     */
    label: {
      type: String,
      required: false,
    },
    /**
     * use dark styling for dropdown button
     */
    darkStyles: {
      type: Boolean,
      required: false,
    },
    /**
     * any other data you want the child to pass back;
     */
    otherData: {
      type: Object,
    },
    bigDropdown: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
    },
    customClass: {
      type: String,
      default: 'h-[48px]',
    },
    placeholderClass: {
      type: String,
      default: '',
    },
    primaryColor: {
      type: Boolean,
      default: false,
    },
    scrollOverflow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SCHEMES,
      ariaExpanded: false,
      menuItems: [],
      selectedItemIndex: 0,
    };
  },
  methods: {
    selectOption(option, index) {
      this.close();
      this.$refs.trigger.focus();
      this.selectedItemIndex = index;
      this.$emit('selection', option, this.otherData);
    },
    toggleMenu() {
      return this.ariaExpanded ? this.close() : this.open();
    },
    open() {
      this.ariaExpanded = true;
      this.$nextTick(() => {
        this.menuItems[this.selectedItemIndex].focus();
      });
    },
    close() {
      this.ariaExpanded = false;
    },
    moveDown(increment = 1) {
      this.selectedItemIndex = this.limitIndexRange(
        this.selectedItemIndex + increment,
        0,
        this.menuItems.length - 1,
      );
      this.menuItems[this.selectedItemIndex].focus();
      this.menuItems[this.selectedItemIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    },
    moveUp(increment = -1) {
      this.moveDown(increment);
    },
    limitIndexRange(operand, min, max) {
      return Math.min(Math.max(operand, min), max);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.menu) {
        this.menuItems = Array.prototype.slice.call(this.$refs.menu.querySelectorAll('li'));
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.menu {
  max-height: 350px;
  top: 100%;
  transition: max-height 0.2s ease-in-out, transform 0.2s ease;

  &.big-menu {
    max-height: 200px;
  }

  &.menu-hidden {
    @apply max-h-0;
  }
}

.iconColorLarge {
  width: 22px;
  height: 22px;
}

.trigger:active + .menu,
.trigger:active {
  @apply transform scale-95;
}

.trigger:focus > span > svg {
  @apply text-primary;
}

.disabled {
  @apply pointer-events-none cursor-default shadow-none bg-gray-200 text-gray-500;
}

.check {
  path {
    @apply fill-primary;
  }
}
</style>
