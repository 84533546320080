<template>
  <div class="relative w-full">
    <textarea
      autocomplete="disabled"
      autocorrect="false"
      :autocapitalize="autocapitalize ? 'sentences' : 'none'"
      spellcheck="false"
      @input="updateValue()"
      @focus="focused = true"
      @blur="focused = false"
      :id="id"
      :name="name"
      :type="type"
      ref="input"
      class="input w-full text-gray-900 bg-white rounded shadow-drop text-sm lg:text-lg placeholder-gray-600 focus:outline-none px-4 min-h-[130px] lg:min-h-32 border transition-colors duration-200 ease resize-none"
      :class="[
        { 'input--filled': value && value.length > 0 },
        { 'border-red-600': invalid },
        { 'border-gray-300': !invalid },
        { 'pt-7 pb-3': label && !compact },
        { 'py-6': !label && !compact },
        { 'py-2 px-2': compact },
      ]"
      :value="value"
      :placeholder="placeholder"
      :rows="rowCount"
      :maxlength="maxLength"
    ></textarea>
    <template v-if="label">
      <label
        class="input__label absolute left-0 text-gray-600 mx-4 pointer-events-none mt-2 origin-top-left transition duration-200 ease-out"
        :class="[{ 'text-red-600': invalid }]"
        :for="id"
      >
        {{ label }}
      </label>
    </template>
  </div>
</template>
<script>
export default {
  name: 'TextArea',
  data() {
    return {
      focused: false,
    };
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * name of the input
     */
    name: {
      type: String,
    },
    /**
     * Placeholder text inside the input
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Input type. Can be changed to password or email, for instance
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * The value for the text input
     */
    value: {
      type: String,
    },
    /**
     * Optional value to set number of rows
     */
    rowCount: {
      type: Number,
    },
    /**
     * The text that will be displayed for the Label
     */
    label: {
      type: String,
    },
    /**
     * Determines whether a field is invalid and should display differently (e.g. a red border)
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * The max number of characters of the input field
     */
    maxLength: {
      type: Number,
      default: 524288, // The HTML spec default value
    },
    /**
     * should the textarea autocapitalize input
     */
    autocapitalize: {
      type: Boolean,
      default: true,
    },
    compact: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue() {
      this.$emit('input', this.$refs.input.value);
    },
  },
};
</script>
<style scoped>
.input__label {
  transform: translateY(0.75rem);
}

.input:focus + .input__label,
.input--filled + .input__label {
  @apply font-bold;
  transform: translateY(0) scale(0.78);
}

.input:focus + .input__label {
  @apply text-primary;
}
</style>
